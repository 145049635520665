import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import backendConfig from '../../../../configs/backendConfig.json';


export const getTags = createAsyncThunk('contactsApp/tags/getTags', async () => {
  const response = await (
    await fetch(`${backendConfig.domain}/accounts-assigneds`, {
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    })
  ).json();
  return response;
});

const tagsAdapter = createEntityAdapter({});

export const { selectAll: selectTags, selectById: selectTagsById } = tagsAdapter.getSelectors(
  (state) => state.contactsApp.tags
);

const tagsSlice = createSlice({
  name: 'contactsApp/tags',
  initialState: tagsAdapter.getInitialState([]),
  reducers: {},
  extraReducers: {
    [getTags.fulfilled]: tagsAdapter.setAll,
  },
});

export default tagsSlice.reducer;
