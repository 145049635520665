/*eslint-disable*/
import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { json } from 'react-router-dom';
import jwtServiceConfig from './jwtServiceConfig';
import backendConfig from '../../../configs/backendConfig.json';


/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {

  state = {
    myData: {}
  }

  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            //this.emit('onAutoLogout', 'Invalid access_token');
            //this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessToken');

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  signInWithEmailAndPassword = (email, password) => {
    
    return new Promise((resolve, reject) => {
                   
        fetch( backendConfig.domain + '/login', {        
        method: "POST",
        body: JSON.stringify(
          {
            "email": email,
            "password": password
          }
        ),
        headers: {"Content-type": "application/json; charset=UTF-8"}
      })
      .then(response => response.json()) 
      .then(json => {
        if (!json.success){
          this.state.myData.error = 
            [{
              "type": "password",
              "message":json.message
            }]
          ;
          reject(this.state.myData.error);
          return;
        }else{
          json = json.data;
          this.state.myData = this.getData(json)
          localStorage.setItem('data_user', JSON.stringify(this.state.myData));
          this.setSession(this.state.myData.access_token);
          resolve(this.state.myData.user);
          this.emit('onLogin', this.state.myData.user);             
        }
      }
      )
      .catch(err => {
        console.log('Error -> ' + err);
      });
    });    
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {  
        let data = window.localStorage.getItem('data_user');
        data = JSON.parse(data);
        this.setSession(data.access_token);
        resolve(data.user);
    });    
  };

  getData = (json) => {
    return          {
      "user": {
        "id": json.id,
        "uuid": json.id,
        "from": "custom-db",
        "is_admin":json.is_admin,
        "role": "admin",
        "data": {
          "id" : json.id,         
          "active": json.is_active,
          "createdAt": json.createdAt,
          "displayName": json.username,
          "photoURL": "assets/images/avatars/brian-hughes.jpg",
          "email": json.email,
          "settings": {
            "layout": {},
            "theme": {}
          },
          "shortcuts": [
            "apps.calendar",
            "apps.mailbox",
            "apps.contacts"
          ]
        }
      },
      "access_token": json.access_token
    };
  }

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('data_user');
      localStorage.removeItem('jwt_access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
    this.emit('onLogout', 'Logged out');
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };
}

const instance = new JwtService();

export default instance;
