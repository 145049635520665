import FuseScrollbars from '@fuse/core/FuseScrollbars';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import withReducer from 'app/store/withReducer';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import NotificationCard from './NotificationCard/NotificationCard';

import '../../../../styles/notification-panel.css';
 
import { dismissAll, dismissItem, getNotifications, selectNotifications } from './store/dataSlice';
import reducer from './store';
import { closeNotificationPanel, selectNotificationPanelState } from './store/stateSlice';
import { selectTypeNotification } from './store/typeSlice';
import { selectClients } from './store/clientSlice';

function NotificationPanel(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector(selectNotificationPanelState);
  const type = useSelector(selectTypeNotification);
  const notifications = useSelector(selectNotifications);
  const clients = useSelector(selectClients);

  useEffect(() => {
    dispatch(getNotifications());
  }, [clients, dispatch, notifications]);

  useEffect(() => {
    if (state) {
      dispatch(closeNotificationPanel());
    }
    // eslint-disable-next-line
  }, [location, dispatch]);

  function handleClose() {
    dispatch(closeNotificationPanel());
  }

  function handleDismiss(id) {
    dispatch(dismissItem(id));
  }
  function handleDismissAll() {
    dispatch(dismissAll(filterNotifications().map((item) => item.id)));
  }

  const filterNotifications = () => {
    const result = notifications
      .filter((item) => (type === 'general' && item.type === type) || (type !== 'general' && (item.type === 'general' || item.type === 'specific')))
      .filter((item) => {
        const ids = clients.map((crm) => crm.account);
        return ids.includes(item.crm);
      });
  
    return result;
  };

  return (
    <>
      <div className={`notification-panel ${state ? 'notification-panel-open' : ''}`}>
        <IconButton className="m-4 absolute top-0 right-0 z-999" onClick={handleClose} size="large">
          <FuseSvgIcon color="action">heroicons-outline:x-circle</FuseSvgIcon>
        </IconButton>

        {filterNotifications().length > 0 ? (
          <div className="flex flex-col" style={{ height: '100%', rowGap: '5px' }}>
            <div className="content-notifications h-100">
              <FuseScrollbars className="p-16">
                <div className="flex flex-col" style={{ rowGap: '15px' }}>
                  {filterNotifications().map((item) => {
                    return (
                      !item.readed && (
                        <NotificationCard
                          key={item.id}
                          className="mb-16"
                          item={item}
                          onClose={handleDismiss}
                        />
                      )
                    );
                  })}
                </div>
              </FuseScrollbars>
            </div>

            <Typography
              className="text-12 cursor-pointer font-semibold"
              color="#000000"
              align="center"
              onClick={handleDismissAll}
            >
              Borrar
            </Typography>
          </div>
        ) : (
          <div className="flex flex-1 items-center justify-center p-16" style={{ height: '100%' }}>
            <Typography className="text-24 text-center" color="text.secondary">
              No hay notificaciones por ahora.
            </Typography>
          </div>
        )}
      </div>

      <div className="notification-overlay" />
    </>
  );
}

export default withReducer('notificationPanel', reducer)(memo(NotificationPanel));
