import { combineReducers } from '@reduxjs/toolkit';
import data from './dataSlice';
import state from './stateSlice';
import type from './typeSlice';
import clients from './clientSlice';

const reducer = combineReducers({
  data,
  state,
  type,
  clients,
});
export default reducer;
