import { lazy } from 'react';

const CustomersDashboardApp = lazy(() => import('./CustomersDashboardApp'));
const ClientDetailDashboardApp = lazy(() => import('./client/ClientDetailDashboardApp'));

const CustomersDashboardAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/customers',
      element: <CustomersDashboardApp />,
    },
    {
      path: 'dashboards/customers/client/:client/:client_name',
      element: <ClientDetailDashboardApp />
    },
  ],
};

export default CustomersDashboardAppConfig;
