import _ from '@lodash';

const ContactModel = (data) =>
  _.defaults(data || {}, {
    username: '',
    email: '',
    is_admin: false,
    password: '',
    assigned_account_id: '',
  });

export default ContactModel;
