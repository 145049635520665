import { createSlice } from '@reduxjs/toolkit';

const clientSlice = createSlice({
  name: 'notificationPanel/clients',
  initialState: [],
  reducers: {
    setClients: (state, action) => action.payload,
  },
});

export const { setClients } = clientSlice.actions;

export const selectClients = ({ notificationPanel }) => notificationPanel.clients;

export default clientSlice.reducer;
