import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useEffect } from 'react';
import reducer from './store';
import { selectNotifications } from './store/dataSlice';
import { toggleNotificationPanel } from './store/stateSlice';
import { selectTypeNotification } from './store/typeSlice';
import { selectClients } from './store/clientSlice';
import NotificationPanel from './NotificationPanel';

function NotificationPanelToggleButton(props) {
  const type = useSelector(selectTypeNotification);
  const notifications = useSelector(selectNotifications);
  const clients = useSelector(selectClients);

  const dispatch = useDispatch();

  useEffect(() => {}, [dispatch]);
 
  const getTotal = () => {
    const result = notifications
        .filter((item) => (type === 'general' && item.type === type) || (type !== 'general' && (item.type === 'general' || item.type === 'specific')))
        .filter((item) => {
        const ids = clients.map((crm) => crm.account);

        if (ids.includes(item.crm)) return item;
      });

    return result.length;
  };

  return (
    <div className="relative">
      <IconButton
        className="w-40 h-40"
        onClick={(ev) => dispatch(toggleNotificationPanel())}
        size="large"
      >
        <Badge badgeContent={getTotal()} color="error" invisible={getTotal() === 0} max={100}>
          {props.children}
        </Badge>
      </IconButton>

      <NotificationPanel />
    </div>
  );
}

NotificationPanelToggleButton.defaultProps = {
  children: <FuseSvgIcon>heroicons-outline:bell</FuseSvgIcon>,
};

export default withReducer('notificationPanel', reducer)(NotificationPanelToggleButton);
