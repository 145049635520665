import Button from '@mui/material/Button';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Box from '@mui/system/Box';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import backendConfig from '../../../../configs/backendConfig.json';
import { selectTags } from '../store/tagsSlice';

import {
  addContact,
  getContact,
  newContact,
  removeContact,
  selectContact,
  updateContact,
} from '../store/contactSlice';


const ContactForm = (props) => {

  const [isEdit, setIsEdit] = useState(false)

  const [schema, setSchema] = useState(
    yup.object().shape({
      username: yup.string().required('You must enter a name'),
      email: yup.string().email('You must enter a valid email').required('You must enter an email'),
      password: yup
        .string()
        .required('Please enter your password.')
        .min(8, 'Password is too short - should be 8 chars minimum.'),
      passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
    })
  );

  useEffect(() => {
    setSchema(
      yup.object().shape({
        username: yup.string().required('You must enter a name'),
        email: yup.string().email('You must enter a valid email').required('You must enter an email'),
        password: isEdit
          ? yup.string().min(8, 'Password is too short - should be 8 chars minimum.')
          : yup.string().required('Please enter your password.'),
        passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
      })
    );
  }, [isEdit]);

  let contact = useSelector(selectContact);
  const accounts = useSelector(selectTags);
  const routeParams = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isCheckbox, setIsCheckbox] = useState({
    is_active: false,
    is_admin: false
  });

  let [assignedAccount, setAssignedAccount] = useState([]);
  let [defaulAccounts, setDefaulAccounts] = useState([]);

  useEffect(() => {  
  }, [assignedAccount, defaulAccounts, accounts]);    

  const { control, watch, reset, handleSubmit, formState, getValues } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleChange = (event) => {
    setIsCheckbox({
      ...isCheckbox,
      [event.target.name]: event.target.checked,
    });
  };

  const { isValid, dirtyFields, errors } = formState;

  const form = watch();

  useEffect(() => {
    if (routeParams.id === 'new') {
      setAssignedAccount(accounts)
      setIsEdit(false)
      dispatch(newContact());
    } else {
      setIsEdit(true)
      getAccountAssignedTo(routeParams.id);
      dispatch(getContact(routeParams.id));
    }
  }, [dispatch, routeParams]);

  useEffect(() => {
    reset({ ...contact });
  }, [contact, reset]);

  function getAccountAssignedTo(id){
    fetch(backendConfig.domain + '/accounts/user/'+id, {    
      method: "GET",
      headers: {"Content-type": "application/json; charset=UTF-8"
        }
      })
      .then(response => response.json()) 
      .then(json => {
        if(json) {
          setAssignedAccount(json)
        }
      }
    )
    .catch(err => {
      console.log('Company Cards -> Error -> fetch ' + backendConfig.domain + '/accounts-summary' + err);
    })
  }

  useEffect(() => {
    if(isEdit && contact){
      setIsCheckbox({
        is_admin: contact.is_admin,
        is_active: contact.is_active
      });      
      let {assigned_account_id} = contact;
      setDefaulAccounts(assigned_account_id)
    }
  }, [isEdit, contact])

  function onSubmit(data) {
    
    const ids = defaulAccounts.map(item => item.id);
    const { is_active, is_admin } = isCheckbox;
    delete data.avatar;
    if(!data.password) {
      data.password = false;
    }
    data = {
      ...data,
      is_active,
      is_admin,
      assigned_account_id : ((ids && ids.length > 0) ? ids : false)
    };

    if (routeParams.id === 'new') {
      dispatch(addContact(data)).then(({ payload }) => {
        navigate('/apps/users');
      });
    } else {
      dispatch(updateContact(data)).then(({ payload }) => {
        navigate('/apps/users');
      });
    }
  }

  function handleRemoveContact() {
    dispatch(removeContact(contact.id)).then(() => {
      navigate('/apps/users');
    });
  }

  if (_.isEmpty(form) || !contact) {
    return <FuseLoading />;
  }
  const { is_active, is_admin } = isCheckbox;

  return (
    <>
      <Box
        className="relative w-full h-160 sm:h-192 px-32 sm:px-48"
        sx={{
          backgroundColor: 'background.default',
        }}
      >
        {contact.background && (
          <img
            className="absolute inset-0 object-cover w-full h-full"
            src={contact.background}
            alt="user background"
          />
        )}
      </Box>

      <div className="relative flex flex-col flex-auto items-center px-24 sm:px-48">
        <div className="w-full">
          <div className="flex flex-auto items-end -mt-64">
            <Controller
              control={control}
              name="avatar"
              render={({ field: { onChange, value } }) => (
                <Box
                  sx={{
                    borderWidth: 4,
                    borderStyle: 'solid',
                    borderColor: 'background.paper',
                  }}
                  className="relative flex items-center justify-center w-128 h-128 rounded-full overflow-hidden"
                >
                  <div className="absolute inset-0 bg-black bg-opacity-50 z-10" />
                  {/* <div className="absolute inset-0 flex items-center justify-center z-20">
                    <div>
                      <label htmlFor="button-avatar" className="flex p-8 cursor-pointer">
                        <input
                          accept="image/*"
                          className="hidden"
                          id="button-avatar"
                          type="file"
                          onChange={async (e) => {
                            
                            function readFileAsync() {
                              return new Promise((resolve, reject) => {
                                const file = e.target.files[0];
                                if (!file) {
                                  return;
                                }
                                const reader = new FileReader();

                                reader.onload = () => {
                                  resolve(`data:${file.type};base64,${btoa(reader.result)}`);
                                };

                                reader.onerror = reject;

                                reader.readAsBinaryString(file);
                              });
                            }

                            const newImage = await readFileAsync();
                            console.log(newImage, "***image");
                            onChange(newImage);
                          }}
                        />
                        <FuseSvgIcon className="text-white">heroicons-outline:camera</FuseSvgIcon>
                      </label>
                    </div>
                    <div>
                      <IconButton
                        onClick={() => {
                          onChange('');
                        }}
                      >
                        <FuseSvgIcon className="text-white">heroicons-solid:trash</FuseSvgIcon>
                      </IconButton>
                    </div>
                  </div> */}
                  <Avatar
                    sx={{
                      backgroundColor: 'background.default',
                      color: 'text.secondary',
                    }}
                    className="object-cover w-full h-full text-64 font-bold"
                    src={value}
                    alt={contact.username}
                  >
                    {contact.username.charAt(0)}
                  </Avatar>
                </Box>
              )}
            />
          </div>
        </div>

          <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
            <Controller
              name="is_admin"
              control={control}
              render={({ field }) => {
                return (
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={is_admin}
                          checked={is_admin}
                          onChange={handleChange}
                          name="is_admin"
                        />
                      }
                      label="Admin"
                    />
                  </FormControl>
                ); 
              }}
            />
            <Controller
              name="is_active"
              control={control}
              render={({ field }) => {
                return (
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={is_active}
                          checked={is_active}
                          onChange={handleChange}
                          name="is_active"
                        />
                      }
                      label="Activo"
                    />
                  </FormControl>
                ); 
              }}
            />
          </div>

        <Controller
          control={control}
          name="username"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="Usuario"
              placeholder="Usuario"
              id="name"
              error={!!errors.username}
              helperText={errors?.username?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>heroicons-solid:user-circle</FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

          <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-32"
                  label="Email"
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FuseSvgIcon size={20}>heroicons-outline:mail</FuseSvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

          <Controller
              control={control}
              name="assigned_account_id"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  multiple
                  id="assigned_account_id"
                  className="mt-32"
                  options={accounts}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(_props, option, { selected }) => (
                    <li {..._props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option.name}
                    </li>
                  )}
                  value={defaulAccounts}
                  onChange={(event, newValue) => {
                    setDefaulAccounts(newValue.map((item) => item));
                  }}
                  inputValue={assignedAccount}
                  onInputChange={(event, newInputValue) => {
                    setAssignedAccount(newInputValue);
                  }}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Empresas" placeholder="Empresas" />}
                />
              )}
          />    

          <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoComplete='new-password'
                  className="mt-32"
                  label="Password"
                  type="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />

            <Controller
              name="passwordConfirm"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoComplete='new-password'
                  className="mt-32"
                  label="Password (Confirm)"
                  type="password"
                  error={!!errors.passwordConfirm}
                  helperText={errors?.passwordConfirm?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />
      </div>

      <Box
        className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t"
        sx={{ backgroundColor: 'background.default' }}
      >
        {routeParams.id !== 'new' && (
          <Button color="error" onClick={handleRemoveContact}>
            Eliminar
          </Button>
        )}
        <Button className="ml-auto" component={NavLinkAdapter} to={-1}>
          Cancelar
        </Button>
        <Button
          className="ml-8"
          variant="contained"
          color="secondary"
          disabled={isEdit ? !isEdit : (_.isEmpty(dirtyFields) || !isValid)} 
          onClick={handleSubmit(onSubmit)}
        > 
          Save
        </Button>
      </Box>
    </>
  );
};

export default ContactForm;
