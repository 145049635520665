import { createSlice } from '@reduxjs/toolkit';

const typeSlice = createSlice({
  name: 'notificationPanel/type',
  initialState: 'general',
  reducers: {
    setType: (state, action) => action.payload,
  },
});

export const { setType } = typeSlice.actions;

export const selectTypeNotification = ({ notificationPanel }) => notificationPanel.type;

export default typeSlice.reducer;
