import Button from '@mui/material/Button';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Box from '@mui/system/Box';
import _ from '@lodash';
import { getContact, selectContact } from '../store/contactSlice';

const ContactView = () => {
  const contact = useSelector(selectContact);
  const routeParams = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContact(routeParams.id));
  }, [dispatch, routeParams]);

  useEffect(() => {
  },[contact])

  if (!contact) {
    return <FuseLoading />;
  }

  return (
    <>
      <Box
        className="relative w-full h-160 sm:h-192 px-32 sm:px-48"
        sx={{
          backgroundColor: 'background.default',
        }}
      >
        {contact.background && (
          <img
            className="absolute inset-0 object-cover w-full h-full"
            src={contact.background}
            alt="user background"
          />
        )}
      </Box>
      <div className="relative flex flex-col flex-auto items-center p-24 pt-0 sm:p-48 sm:pt-0">
        <div className="w-full max-w-3xl">
          <div className="flex flex-auto items-end -mt-64">
            <Avatar
              sx={{
                borderWidth: 4,
                borderStyle: 'solid',
                borderColor: 'background.paper',
                backgroundColor: 'background.default',
                color: 'text.secondary',
              }}
              className="w-128 h-128 text-64 font-bold"
              src={(contact.avatar) ? contact.avatar : ''}
              alt={(contact.username) ? contact.username : ''}
            >
              {(contact.username) ? contact.username.charAt(0) : ''}
            </Avatar>
            <div className="flex items-center ml-auto mb-4">
              <Button variant="contained" color="secondary" component={NavLinkAdapter} to="edit">
                <FuseSvgIcon size={20}>heroicons-outline:pencil-alt</FuseSvgIcon>
                <span className="mx-8">Editar</span>
              </Button>
            </div>
          </div>

          <Typography className="mt-12 text-4xl font-bold truncate">{(contact.username) ? contact.username : ''}</Typography>

          <div className="flex flex-wrap items-center mt-8">
          {contact &&
            contact.assigned_account_id &&
            contact.assigned_account_id.map((account, index) => {
              if (account.assigned_user_id != null) {
                return (
                  <Chip
                    key={`${account.id}_${index}`}
                    label={account.name}
                    className="mr-12 mb-12"
                    size="small"
                  />
                );
              }
            })}
        </div>

          <Divider className="mt-16 mb-24" />

          <div className="flex flex-col space-y-32">
            {contact.email && (
              <div className="flex items-center">
                <FuseSvgIcon>heroicons-outline:mail</FuseSvgIcon>
                <div className="ml-24 leading-6">{contact.email}</div>
              </div>
            )}
          </div>
          <div className="flex flex-col space-y-32">
              <div className="flex items-center">
              <FuseSvgIcon size={20}>heroicons-solid:user-circle</FuseSvgIcon>
                <div className="ml-24 leading-6">¿Es admin? {(contact.is_admin) ? 'Si' : 'No'}</div>
              </div>
          </div>
          <div className="flex flex-col space-y-32">
              <div className="flex items-center">
              <FuseSvgIcon size={20}>heroicons-solid:user-circle</FuseSvgIcon>
                <div className="ml-24 leading-6">¿Está activo? {(contact.is_active) ? 'Si' : 'No'}</div>
              </div>
          </div>
        </div>
      </div> 
    </>
  );
};

export default ContactView;
