import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { Checkbox } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useRef } from 'react';

import './NotificationCard.css';

export default function NotificationCard(props) {
  const { item } = props;
  const date = new Date(item.createdAt);
  const card = useRef(null);

  const handleClose = (ev) => {
    card.current.classList.add('notification-card-deleted');

    setTimeout(() => {
      if (props.onClose) {
        props.onClose(item.id);
      }
    }, 500);
  };

  const getIcon = (type) => {
    switch (type) {
      case 'businessDeleted':
        return (
          <img src="assets/icons/notifications/emote-business-deleted.svg" alt="Business deleted" />
        );
      case 'email':
        return <img src="assets/icons/notifications/emote-email.svg" alt="Email" />;
      case 'moneyMouth':
        return <img src="assets/icons/notifications/emote-money-mouth.svg" alt="Money Mouth" />;
      case 'notebook':
        return <img src="assets/icons/notifications/emote-notebook.svg" alt="Notebook" />;
      case 'rocket':
        return <img src="assets/icons/notifications/emote-rocket.svg" alt="Rocket" />;
      case 'thinking':
        return <img src="assets/icons/notifications/emote-thinking.svg" alt="Thinking" />;
      case 'worry':
        return <img src="assets/icons/notifications/emote-worry.svg" alt="Worry" />;
      case 'astonished':
        return <img src="assets/icons/notifications/emote-astonished.svg" alt="Astonished" />;
      case 'landingError':
        return <img src="assets/icons/notifications/landing-error.svg" alt="Landing Error" />;
      case 'user':
        return <img src="assets/icons/notifications/user.svg" alt="User" />;
      default:
        return (
          <Box
            sx={{ backgroundColor: 'background.default' }}
            className="flex shrink-0 items-center justify-center w-32 h-32 mr-12 rounded-full"
          >
            <FuseSvgIcon className="opacity-75" color="inherit">
              heroicons-solid:bell
            </FuseSvgIcon>
          </Box>
        );
    }
  };

  return (
    <Card
      className="notification-card flex items-center relative w-full rounded-6 min-h-74 shadow"
      elevation={0}
      ref={card}
    >
      <div className="notification-info flex">
        <div className="notification-icon">{getIcon(item.icon)}</div>

        <div className="notification-data flex flex-col">
          {item.title && (
            <Typography
              title={item.title}
              fontSize={15}
              className="font-semibold notification-title"
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          )}

          {item.description && item.createdAt && (
            <div>
              <Typography title={item.description} fontSize={11} color="rgba(0, 0, 0, 0.44)">
                {item.description}
              </Typography>

              <Typography title={item.description} fontSize={11} color="rgba(0, 0, 0, 0.44)">
               Fecha: {date.getFullYear()}-{(date.getMonth() + 1).toString().padStart(2, '0')}-{date.getDate().toString().padStart(2, '0')}
              </Typography>
            </div>
          )}

          {item.link && (
            <a className="font-semibold" href={item.link} target="__blank">
              Ver más
            </a>
          )}
        </div>
      </div>

      <div className="notification-content-check">
        <IconButton
          disableRipple
          className="p-8"
          color="inherit"
          size="small"
          onClick={handleClose}
        >
          <Checkbox color="default" />
        </IconButton>
      </div>
    </Card>
  );
}
