import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';
import ContactModel from '../model/ContactModel';
import backendConfig from '../../../../configs/backendConfig.json';


export const addContact = createAsyncThunk(
  'contactsApp/contacts/addContact',
  async (contact, { dispatch, getState }) => {
    try{
      const response = await (
        await fetch(`${backendConfig.domain}/signup`, {
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
          method: "POST",
          body: JSON.stringify(contact)
        })
      ).json();
      return response.data;
    }catch (error) {
      history.push({ pathname: `/apps/users` });
      return null;
    }
  }
);

export const getContact = createAsyncThunk('contactsApp/contacts/getContact', async (id) => {
  try{
    const response = await (
      await fetch(`${backendConfig.domain}/user/${id}`, {
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      })
    ).json();
    delete response.password;
    return response;
  }catch (error) {
    history.push({ pathname: `/apps/users` });
    return null;
  }
});

export const updateContact = createAsyncThunk(
  'contactsApp/contacts/updateContact',
  async (contact, { dispatch, getState }) => {
    try{
      let {id} = contact;
      const response = await (
        await fetch(`${backendConfig.domain}/user/${id}`, {
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
          method: "PUT",
          body: JSON.stringify(contact)
        })
      ).json();
      return response.data;
    }catch (error) {
      history.push({ pathname: `/apps/users` });
      return null;
    }
  }
);

export const removeContact = createAsyncThunk(
  'contactsApp/contacts/removeContact',
  async (id, { dispatch, getState }) => {
    try {
      const response = await (
        await fetch(`${backendConfig.domain}/user/${id}`, {
          method: 'DELETE',
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
        })
      ).json();
      return response;
    }catch (error) {
      history.push({ pathname: `/apps/users` });
      return null;
    }
  }
);

export const selectContact = ({ contactsApp }) => contactsApp.contact;
const contactSlice = createSlice({
  name: 'contactsApp/contact',
  initialState: null,
  reducers: {
    newContact: (state, action) => ContactModel(),
    resetContact: () => null,
  },
  extraReducers: {
    [getContact.pending]: (state, action) => null,
    [getContact.fulfilled]: (state, action) => action.payload,
    [updateContact.fulfilled]: (state, action) => action.payload,
    [removeContact.fulfilled]: (state, action) => null,
  },
});

export const { resetContact, newContact } = contactSlice.actions;

export default contactSlice.reducer;
